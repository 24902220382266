<template>
  <div class="box1">
    <div class="box">
      <div class="header">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="banner">
        <img src="@/assets/banner.gif" alt="" />
      </div>
      <div class="content">
        <div class="contentTop">
          <div>
            <img src="@/assets/1.gif" alt="" />
          </div>
          <div class="contentTop_right">
            <p class="conspicuous">办公系统</p>
            <p class="normal">全新的办公模式，让办公摆脱时间和空间的束缚</p>
            <p class="conspicuous">优势</p>
            <p class="normal">可塑性强</p>
            <p class="normal">优化企业业务流程</p>
            <p class="normal">私有云部署，安全稳定</p>
            <p class="normal">降低企业的管理和数字化实施成本</p>
            <p class="normal">简单功能零代码配置，复杂功能低代码开发</p>
          </div>
        </div>
        <div class="contentBottom">
          <div class="contentTop_left">
            <p class="conspicuous">定制小程序公众号</p>
            <p class="normal">面对B端C端的小程序公众号服务</p>
            <p class="conspicuous">优势</p>
            <p class="normal">轻变量，随用随走</p>
            <p class="normal">自带流量入口,成本低实施快</p>
            <p class="normal">统一代码框架，更流程的使用体验</p>
            <p class="normal">高效的流量召回，快速的使用使用策略</p>
            <p class="normal">小程序+公众号完美结合，社交，内容，服务轻松变现</p>
          </div>
          <div>
            <img src="@/assets/2.gif" alt="" />
          </div>
        </div>
      </div>
      <div class="introductory">
        <p class="introductory_top">公司简介</p>
        <p class="normal">博创是一家新型智慧化企业</p>
        <p class="normal" style="paddingLeft:146px;">全方位数字化场景</p>
        <p class="normal" style="paddingLeft:230px;">公司拥有成熟的技术研发团队</p>
        <p class="normal" style="paddingLeft:287px;">以客户为中心，提供有价值的产品和服务</p>
        <p class="normal">博创着力推动科技创新与企业数字沉淀，助力各行业升级，为促进企业的长远发展做技术积累</p>
      </div>
      <div class="cooperation">
        <p>合作洽谈</p>
        <p>chenganqing@dingtalk.com</p>
      </div>
      <div class="mark">
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备17048892号-1</a>
        <p>copyright @ 2021 bochuangxxkj.com 版权所有</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style >
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
body {
  font: 12px “微软雅黑”;
}
li {
  list-style: none;
}
img {
  vertical-align: middle;
}

.box {
  width: 1024px;
  margin: 0 auto;
    background-color: #f2f2f2;
}
.header {
  height: 95px;
}
.contentTop {
  display: flex;
}
.contentTop div {
  width: 50%;
}
.contentTop_right {
  padding-top: 21px;
  padding-left: 37px;
}
.conspicuous {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 27px;
}
.normal {
  font-size: 18px;
  margin-bottom: 13px;
}
.contentBottom {
  display: flex;
}
.contentBottom div {
  width: 50%;
}
.contentBottom p {
  text-align: right;
}
.contentTop_left{
  padding-top: 21px;
  padding-right: 37px;
}
.introductory{
  padding-top: 60px;
  padding-left: 40px;
  background: #ffffff;
  padding-bottom: 63px;
}
.introductory_top{
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 15px;
}
.cooperation{
  margin-top: 18px;
  padding: 24px 0 32px 92px;
  background: #ffffff;
  display: flex;
}
.cooperation p{
  font-size: 22px;
  margin-right: 62px;
}
.mark{
  display: flex;
  padding:25px 32px 26px 92px;
  justify-content: space-between;
}
.mark p{
  font-size: 16px;
}
.mark a{
   font-size: 16px;
   color: #333333;
}
</style>